import React, { useState } from 'react';
import './ImageSlider.css';
import { IoIosArrowDropleftCircle, IoIosArrowDroprightCircle } from "react-icons/io";
import { IoClose } from "react-icons/io5";

const ImageSlider = ({ images, onClose }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const prevSlide = () => {
    const newIndex = (currentIndex === 0) ? images.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const nextSlide = () => {
    const newIndex = (currentIndex === images.length - 1) ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  return (
    <div className="slider-overlay">
      <div className="slider">
        <button className="close-button" onClick={onClose}><IoClose /></button>
        <div className="slider__image-container">
          <img src={images[currentIndex].url} alt={`Slide ${currentIndex + 1}`} className="slider__image"/>
        </div>
        <div className="slider__nav">
          <button className="slider__nav-btn" onClick={prevSlide}><IoIosArrowDropleftCircle /></button>
          <span>{currentIndex + 1} / {images.length}</span>
          <button className="slider__nav-btn" onClick={nextSlide}><IoIosArrowDroprightCircle /></button>
        </div>        
      </div>
    </div>
  );
};

export default ImageSlider;
