import React from 'react';
import './Banner.css';
import { Link } from 'react-router-dom/cjs/react-router-dom';

export function Banner({props}) {
  return (
    <Link to={`/shop`} className="banner-link">
      <div style={{background: `url(${props.image}) no-repeat center center / cover`}} className="banner">
        <div className="banner-content">
          <h1>{props.title}</h1>
          <p>{props.subTitle}</p>
          <button>Магазин</button>
        </div>
      </div>
    </Link>
  );
}

export default Banner;
