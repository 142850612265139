import React, { useEffect, useState } from "react";
import "./Home.css";
import ProductCard from "./ProductCard";
import MataData from "../layouts/MataData/MataData";
import { clearErrors, getProduct } from "../../actions/productAction";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../layouts/loader/Loader";
import { useAlert } from "react-alert";
import HeroSlider from "./HeroSilder";
import Banner from "./BannerStack/Banner";
import ProductGrid from "./BannerStack/ProductGrid";
import { mainBanner, shuffleArray, sliderData } from "../../constants/homeConstants";
import SliderComponent from "./BannerStack/SliderComponent";
import { Link } from 'react-router-dom/cjs/react-router-dom';

function Home() {
  // we provided all parameter for react-alert at index.js
  const [shuffledProducts, setShuffledProducts] = useState([]);
  const alert = useAlert();
  const dispatch = useDispatch();
  const { loading, error, products } = useSelector((state) => state.products);

  useEffect(() => {
    setShuffledProducts(shuffleArray(products || [])?.slice(0, 9));
  }, [products])

  React.useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors);
    }
    dispatch(getProduct());
  }, [dispatch, error, alert]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <>
            <MataData title="Sneaker Brigade" />
            <div className="Home_Page">
              <HeroSlider />
              {/* <div className="feature" style={{ marginTop: "2.7rem" }}>
                <h2
                  style={{
                    textAlign: "center",
                    fontFamily: `"Archivo", sans-serif`,
                    fontWeight: "800",
                  }}
                >
                  Любими продукти
                </h2>
                {products &&
             
                
                       <FeaturedSlider   products ={products}/> }
                  
                 
              
              </div> */}
              {/* <ProductAnimation></ProductAnimation> */}
              {/* <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <CardStack />
              </div> */}
              {/* <h2 className="trending_heading">Купи от SneakerBrigade</h2>
              <div className="trending-products">
                {products &&
                  products.map((product) => (
                    <CardSection key={product._id} product={product} />
                  ))}
              </div> */}
              <Banner props={mainBanner} />
              <ProductGrid/>
              <br/>
              <h1>Популярни обувки</h1> 
              <SliderComponent> 
                {shuffledProducts &&
                  (shuffledProducts || []).map((product) => (
                    <ProductCard key={product._id} product={product} />
                  )
                )}
              </SliderComponent>
              <br/>
              <h1>Най-известни модели</h1>
              <SliderComponent>
                {shuffleArray(sliderData || []).map((item, index) => (
                  <div key={index} className="slider-item">
                    <Link to={`/shop/${item.url}`} rel="noopener noreferrer">
                      <img src={item.image} alt={item.name} />
                      <h3>{item.name}</h3>
                    </Link>
                  </div>
                ))}
              </SliderComponent>
            </div>
          </>
        </>
      )}
    </>
  );
}

export default Home;
