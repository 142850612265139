import { nike, nb, yeezy, af1, aj4, aj1, scorpion, yeezy1 } from '../Image/home/img';
import { categories } from './productsConstatns';

export const gridImages = [
    { id: 1, title: 'NK/AJ Just do it', image: nike, link: '#' },
    { id: 2, title: 'NB Elevate your game', image: nb, link: '#' }
  ];


export const mainBanner = {
    image: yeezy,
    title: 'Yeezy',
    subTitle: 'Модерен стил, който променя играта и преодолява граници.',
}

export function shuffleArray(array) {
  return array.sort(() => Math.random() - 0.5);
}

export const slides = [
  {
    image: require("../Image/Cricket-wepon/04.jpg"),
    quote: "Elevate your streetwear game with Yeezy – where comfort meets cutting-edge style",
    saleText: "YEEZY BOOST V2 EXCLUSIVE",
    productText: "Yeezy Boost V2",
    href: categories[0]
  },
  {
    image: require("../Image/Cricket-wepon/03.jpg"),
    quote: "Elevate your streetwear game with NK Dunk – where comfort meets cutting-edge style",
    saleText: "NK Dunk Low",
    productText: "NK Dunk Low",
    href: categories[3]
  },
  {
    image: require("../Image/Cricket-wepon/07.JPG"),
    quote: "Embrace the day with NK VaporMax – the peak of cutting-edge design and performance.",
    saleText: "NK Vapormax",
    productText: "NK Vapormax",
    href: categories[7]
  },
  
  {
    image: require("../Image/Cricket-wepon/09.JPG"),
    quote: "Discover the future of iconic style and performance with the AJ Retro 4 – where heritage meets innovation.",
    saleText: "AJ Retro 4",
    productText: "AJ Retro 4",
    href: categories[8]
  },
  {
    image: require("../Image/Cricket-wepon/10.JPG"),
    quote: "Experience unparalleled comfort and timeless style with NB – the perfect fusion of tradition and innovation.",
    saleText: "NB ",
    productText: "NB",
    href: categories[11]

  },
  {
    image: require("../Image/Cricket-wepon/05.JPG"),
    quote: "Experience the ultimate in style and comfort with Yeezy, available at the best prices you’ll find.",
    saleText: "YEEZY BOOST 350 EXCLUSIVE",
    productText: "Yeezy Boost 350",
    href: categories[0]
  },
  {
    image: require("../Image/Cricket-wepon/08.JPG"),
    quote: "Step into the future with NK Scorpion – the pinnacle of innovative design and performance.",
    saleText: "NK Scorpion",
    productText: "NK",
    href: categories[2]
  },
  
];


export const sliderData = [
  {
      name: "AF1",
      image: af1,
      url: `${categories[4]}`
  },
  {
      name: " AJ4",
      image: aj4,
      url: `${categories[8]}`
  },
  {
      name: " AJ1",
      image: aj1,
      url: `${categories[9]}`
  },
  {
      name: "NK Scorpion",
      image: scorpion,
      url: `${categories[2]}`
  }
  ,
  {
      name: "Yeezy Boost ",
      image: yeezy1,
      url: `${categories[0]}`
  }
];
