import React from "react";
import "./TermsAndCondtion.css";
import MetaData from "../component/layouts/MataData/MataData";
import TermsImage from "../Image/about/tc.png";
const TermsAndConditionsPage = () => {
  return (
    <div className="terms-container">
      <MetaData title="Terms and Conditions" />
      {/* <img
        src={TermsImage}
        alt="Terms and Conditions"
        className="terms-image"
      /> */}
      <div className="terms-overlay">
        <h1 className="terms-title">Доставка и плащане</h1>
      </div>
      <div className="terms-content">
      <p>
  Заплащането за направените поръчки в онлайн магазина се извършва с:
</p>
<p>
  Наложен платеж – в брой при получаване на пратката от куриер.
</p>
<h2>ПРЕГЛЕД НА СТОКИ ПРЕДИ ПЛАЩАНЕ</h2>
<p>
  Ние Ви даваме възможност при получаване на стоката от куриера или от офис на еконт да я отворите пред него, да я разгледате в рамките на 15 мин. и да я тествате преди да заплатите.
</p>
<p>
  В случай, че стоката не отговаря на очакванията Ви, имате правото да я върнете обратно на куриера или да я замените с нова, като разходите за обратна доставка се поемат от Вас.
</p>
<p>
  Разбира се, Вие можете да приемете стоката от куриера, като я заплатите (при плащане с наложен платеж) и да я пробвате на спокойствие в домашни условия.
</p>
<p>
  При отказване на пратка след преглед и тест стойността на куриерската услуга за връщане на продуктите към нас е за Ваша сметка (за сметка на Клиента).
</p>
<p>
  Цените за доставка на куриерска фирма еконт експрес се определят според теглото, наложения платеж и начина на доставка.
</p>

      </div>
    </div>
  );
};

export default TermsAndConditionsPage;
