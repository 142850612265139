import React, { useEffect } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { ExitToApp as LogoutIcon } from "@material-ui/icons";
import { Link } from "react-router-dom";
import "./Profile.css";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../actions/userAction";
import { useAlert } from "react-alert";

const ProfilePage = () => {
  const alert = useAlert();
  const dispatch = useDispatch();
  const history = useHistory();
  const { user, isAuthenticated } = useSelector((state) => state.userData);

  const logoutHandler = () => {
    dispatch(logout());
  };

  useEffect(() => {
    if (isAuthenticated === false) {
      history.push("/login");
    }
  }, [history, isAuthenticated]);

  const createdAt = (user) => {
    const createdAt = new Date(user?.createdAt);
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
      timeZone: "Asia/Kolkata",
    };

    const formatter = new Intl.DateTimeFormat("en-IN", options);
    const formattedDate = formatter.format(createdAt);
    return formattedDate;
  };

  return (
    <div className="rootProfile">
      <div className="header-root">
        <Typography variant="h5" component="h1" className="headingProfile">
          Здравейте, {user.name} !
        </Typography>
        <Typography variant="body2" className="greeting">
          Добре дошли в профила си на Sneaker Brigade
        </Typography>
      </div>

      <div className="profileContainer">
        <div className="leftContainer">
          <h4 className="profileHeadingLeft">Вашият Профил</h4>
          <div className="profileSection">
            <Avatar
              alt={user.name}
              src={user.avatar?.url}
              className="profileAvatar"
            />
            <div className="leftDetails">
              <Typography className="profileText">
                <h5 className="profileSubHeading">Име :</h5>
                {user.name}
              </Typography>
              <Typography className="profileText">
                <h5 className="profileSubHeading">Имейл :</h5>
                {user.email}
              </Typography>
              <Typography className="profileText">
                <h5 className="profileSubHeading">Присъединил се на :</h5>
                {createdAt(user.createdAt ? user : null)}
              </Typography>
            </div>
          </div>
          <div className="myOrder">
            <Typography variant="h4" component="h1" className="profileHeading">
              Поръчки
            </Typography>
            <Link to="/orders" style={{ textDecoration: "none", color: "inherit" }}>
              <Button variant="contained" className="ordersButton">
                Вижте поръчките си
              </Button>
            </Link>
          </div>
        </div>

        <div className="rightContainer">
          <div className="rightHeadings">
            <Typography variant="h4" component="h1" className="profileHeading">
              Лична информация
            </Typography>
            <Typography className="profileText2">
              Здравейте! Това е вашият профил. Тук можете да видите и редактирате личните си данни.
            </Typography>
          </div>
          <div className="myOrder">
            <div className="details">
              <Typography variant="h4" component="h1" className="profileHeading">
                Лична информация
              </Typography>
              <Typography className="profileSubHeading">Име</Typography>
              <Typography className="profileText">{user.name}</Typography>
            </div>
            <Link to="/profile/update" style={{ textDecoration: "none" }}>
              <Button variant="contained" className="profileButton">
                Редактирай
              </Button>
            </Link>
            <div className="details">
              <Typography
                variant="h4"
                component="h1"
                className="profileHeading"
                style={{ marginTop: "1.5rem" }}
              >
                LOGIN детайли
              </Typography>
              <Typography className="profileSubHeading">EMAIL</Typography>
              <Typography className="profileText">{user.email}</Typography>
              <Typography className="profileSubHeading" style={{ marginTop: "10px" }}>
                Парола
              </Typography>
              <Typography className="profileSubHeading">
                *************
              </Typography>
            </div>
            <Link to="/password/update" style={{ textDecoration: "none", color: "inherit" }}>
              <Button variant="contained" className="profileButton">
                Обнови парола
              </Button>
            </Link>
            <div className="manageAccount">
              <p className="profileText2">
                За да получите отново достъп до уебсайта на Sneaker Brigade, трябва да предоставите своите login данни. Това действие ще ви изведе от всички други уеб браузъри, които сте използвали преди това.
              </p>
            </div>
            <Button
              variant="contained"
              color="primary"
              className="profileButton"
              startIcon={<LogoutIcon />}
              onClick={logoutHandler}
            >
              Изход
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;
