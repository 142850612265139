import React, { useEffect, useState } from "react";
import "./Products.css";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../layouts/loader/Loader";
import { useAlert } from "react-alert";
import { useRouteMatch } from "react-router-dom";
import MetaData from "../layouts/MataData/MataData";
import { clearErrors, getProduct } from "../../actions/productAction";
import ProductCard from "../Home/ProductCard";
import Pagination from "react-js-pagination";
import Slider from "@mui/material/Slider";
import { Typography } from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import InventoryIcon from "@mui/icons-material/Inventory";
import { useParams } from 'react-router-dom';
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useMediaQuery } from "@material-ui/core";
import { useTheme } from "@emotion/react";
import { categories } from "../../constants/productsConstatns";
import { FaAngleDoubleLeft, FaAngleLeft, FaAngleDoubleRight, FaAngleRight } from "react-icons/fa";

function Products() {
  const match = useRouteMatch();
  let keyword = match.params.keyword;
  const dispatch = useDispatch();
  const {
    products,
    loading,
    productsCount,
    filteredProductCount,
    error,
    resultPerPage,
  } = useSelector((state) => state.products);
  const productState = useSelector((state) => state.products);
  const alert = useAlert();
  const { categoryHeader } = useParams();
  const [currentPage, setCurrentPage] = React.useState();
  const [price, setPrice] = React.useState([0, 1000]); // initial limit from min=0 to max=100000
  const [category, setCategory] = React.useState(categoryHeader || "");
  const [ratings, setRatings] = React.useState(0);
  const [selectedCategory, setSelectedCategory] = React.useState("");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
    dispatch(getProduct(keyword, currentPage, price, category, ratings));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, keyword, currentPage, price, ratings, category, categoryHeader]);

  const setCurrentPageNoHandler = (e) => {
    setCurrentPage(e); // e is the clicked page value
  };

  const priceHandler = (event, newPrice) => {
    setPrice(newPrice);
  };
  const handleCategoryChange = (category) => {
    // window.fbq('trackCustom', 'SelectedCategory', {
    //   search_category: category,
    // });
    
    setCategory(category);
    setSelectedCategory(category);
    // Perform any additional actions or filtering based on the selected category
  };
// const [selectedRating, setSelectedRating] = React.useState("all");

// const handleRatingChange = (event) => {
//   setRatings(event.target.value);
//   setSelectedRating(event.target.value);
//   // Trigger filtering with the selected rating value or perform any other action
  
// };
 
useEffect(() => {
  if (categoryHeader) {
    handleCategoryChange(categoryHeader);
  }
}, [categoryHeader]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <MetaData title="Shop" />
          {products === undefined || products?.length === 0 ? (
            <>
              <div
                className="emptyCartContainer "
                style={{ marginTop: "5rem", background: "white" }}
              >
                <InventoryIcon fontSize="100" className="cartIcon" />

                <Typography variant="h5" component="h1" className="cartHeading">
                  Не са намерени продукти
                </Typography>
                <Typography variant="body" className="cartText">
                  Няма избрани продукти
                </Typography>
                <Typography variant="body" className="cartText">
                  Няма продукт с това име
                </Typography>

                <Button
                  className="shopNowButton"
                  onClick={() => window.location.reload()}
                  style={{ width: "7rem" }}
                >
                  Презареди
                </Button>
              </div>
            </>
          ) : (
            <div>
            <div className="productPage" style={isMobile ? { flexDirection: 'column' } : {}}>
              <div className="prodcutPageTop">
                <div className="filterBox"  style={{width: '350px', }}>
                  {/* Price */}
                  <Typography
                  style={{
                    fontSize: "25px",
                    padding: "5px",
                    fontWeight: 700,
                    color: "#414141",
                    
                  }}
                >
                  Филтър
                </Typography>
                <br/>
                  <div className="priceFilter">
                    <Typography
                      style={{
                        fontSize: "18px",
                        padding: "5px",
                        fontWeight: 700,
                        color: "#414141",
                        
                      }}
                    >
                      Цена
                    </Typography>
                    <div className="priceSlider">
                      <Slider
                        value={price}
                        onChange={priceHandler}
                        min={0}
                        max={1000}
                        step={10}
                        valueLabelDisplay="auto"
                        aria-labelledby="range-slider"
                        
                      />
                    </div>
                    <div className="priceSelectors">
                      <div className="priceSelector">
                        <Select
                          value={price[0]}
                          onChange={(e) =>
                            setPrice([+e.target.value, price[1]])
                          }
                          className="priceOption"
                          IconComponent={ArrowDropDownIcon}
                          renderValue={(selected) =>
                            selected !== "" ? selected : "min"
                          } // Display "min" as default label
                        >
                          <MenuItem value={100} className="menu_item">
                            100
                          </MenuItem>
                          <br/>
                          <MenuItem value={150} className="menu_item">
                            150
                          </MenuItem>                          
                          <br/>
                          <MenuItem value={200} className="menu_item">
                            200
                          </MenuItem>                          
                          <br/>
                          <MenuItem value={250} className="menu_item">
                            250
                          </MenuItem>
                        </Select>
                        <span className="toText">до</span>
                        <Select
                          value={price[1]}
                          onChange={(e) =>
                            setPrice([price[0], +e.target.value])
                          }
                          className="priceOption"
                          IconComponent={ArrowDropDownIcon}
                          renderValue={(selected) =>
                            selected !== "" ? selected : "max"
                          }
                        >
                         <MenuItem value={300} className="menu_item">
                            300
                          </MenuItem>                          
                          <br/>
                          <MenuItem value={450} className="menu_item">
                            450
                          </MenuItem>                          
                          <br/>
                          <MenuItem value={600} className="menu_item">
                            600
                          </MenuItem>                          
                          <br/>
                          <MenuItem value={750} className="menu_item">
                            750
                          </MenuItem>
                          {/* Add more options as per your requirement */}
                        </Select>
                      </div>
                    </div>
                  </div>

                  <div className="filter_divider"></div>
                  {/* Categories */}
                  <div className="categoriesFilter">
                    <Typography
                      style={{
                        fontSize: "18px",
                        padding: "10px",
                        fontWeight: 700,
                        color: "#414141",
                      }}
                    >
                      Категории
                    </Typography>
                    <ul className="categoryBox">
                      {categories.map((category, index) => (
                        <li className="category-link" key={index}>
                          <label
                            htmlFor={`category-${index}`}
                            className="category-label"
                          >
                            <input
                              type="checkbox"
                              id={`category-${index}`}
                              className="category-checkbox"
                              value={category}
                              checked={category === selectedCategory}
                              onChange={() => handleCategoryChange(category)}
                            />
                            Обувки {category}
                          </label>
                        </li>
                      ))}
                    </ul>
                  </div>

                
                  {/* Ratings */}
                  {/* <div className="ratingsFilter">
                    <Typography
                      style={{
                        fontSize: "18px",
                        padding: "10px",
                        fontWeight: 700,
                        color: "#414141",
                      }}
                    >
                      Ratings Above
                    </Typography>
                    <RadioGroup
                      value={selectedRating}
                      onChange={handleRatingChange}
                      row
                      className="ratingsBox"
                    >
                      <FormControlLabel
                        value="4"
                        control={<Radio />}
                        label="4★ & above"
                      />
                      <FormControlLabel
                        value="3"
                        control={<Radio />}
                        label="3★ & above"
                      />
                      <FormControlLabel
                        value="2"
                        control={<Radio />}
                        label="2★ & above"
                      />
                    </RadioGroup>
                  </div>
                  <div className="filter_divider"></div> */}
                  </div>
                  {/* Clear Filters */}
                </div>

                <div
                  className={products?.length < 2 ? "products1" : "products"}
                >
                  {products &&
                    products.map((product) => (
                      <ProductCard key={product._id} product={product} />
                    ))}
                </div>
              </div>

              {/* Pagination */}
                <div className="paginationBox">
                  <Pagination
                    activePage={currentPage}
                    itemsCountPerPage={resultPerPage}
                    totalItemsCount={filteredProductCount}
                    onChange={setCurrentPageNoHandler}
                    nextPageText={<FaAngleRight />}
                    prevPageText={<FaAngleLeft />}
                    firstPageText={<FaAngleDoubleLeft/>}
                    lastPageText={<FaAngleDoubleRight/>}
                    itemClass="page-item"
                    linkClass="page-link"
                    activeClass="pageItemActive"
                    activeLinkClass="pageLinkActive"
                  />
                </div>
             
            </div>
          )}
        </>
      )}
    </>
  );
}

export default Products;
