import React from "react";
import {Link} from "react-router-dom";
import "./Privacy.css";
import MetaData from "../component/layouts/MataData/MataData";

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-container">
     <MetaData title={"Privacy Policy"} />
      <div className="container___">
      <h2>ЗАМЯНА</h2>
<p>Доставката в двете посоки се поема от страна на клиента.</p>
<h3>Процедура:</h3>
<ol>
  <li>
    Трябва да ни уведомите предварително, че желаете да извършите замяна на email – <a href="mailto:support@sneakerbrigade.com">support@sneakerbrigade.com</a>
    <ul>
      <li>Моля, не изпращайте пратка към нас преди да сме потвърдили, че можем да извършим замяна.</li>
      <li>Sneakerbrigade не се задължава да заменя продукти, които не могат да бъдат заменени поради липса на наличност от желаните продукти!</li>
    </ul>
  </li>
  <li>
    Трябва да изпратите пратката до адреса, който е уговорен при свързването с нашия екип.
    <ul>
      <li>Моля, опаковайте пратката с оригиналната й опаковка с цел предпазването й от замърсяване или повреждане по време на доставката до нас.</li>
    </ul>
  </li>
</ol>
<p>Заменяме само продукти, които отговарят на следните изисквания:</p>
<ul>
  <li>Продуктът не е носен или пран</li>
  <li>Продуктът не е повреден и няма дефекти</li>
  <li>Продуктът се връща в оригиналната опаковка, заедно с всички етикети, по начин, по който е бил получен.</li>
</ul>
<p>Моля, имайте предвид, че изпращането на новата пратка се случва в рамките на 3-дневен срок след обработката на върнатата пратка.</p>

<h2>ВРЪЩАНЕ</h2>
<p>Доставката се поема от страна на клиента.</p>
<h3>Процедура:</h3>
<ol>
  <li>Трябва да ни уведомите предварително, че желаете да върнете артикули с цел възстановяване на сумата на email – <a href="mailto:support@sneakerbrigade.com">support@sneakerbrigade.com</a></li>
  <li>Трябва да изпратите пратката до адреса, който е уговорен при свързването с нашия екип.</li>
</ol>

<h3>ВАЖНА ИНФОРМАЦИЯ:</h3>
<p>Потребителят има право да върне закупената от него стока в рамките на 14 работни дни от датата на получаването й. За целта продуктът трябва да е в оригиналната си опаковка, без следи от употреба или нарушаване на търговския вид на продукта в съответствие с разпоредбите на чл.55(ал.1) от ЗЗП. След проверка в срок от 14 дни на купувача, който поема всички транспортни разходи.</p>

<h3>Изисквания за двете процедури:</h3>
<ol>
  <li>Замяната или рекламацията да се случат в 14-дневен срок от датата на получаване на пратката.</li>
  <li>Продукти, подлежащи на връщане или замяна са тези, които не са преминали никаква обработка (пране, гладене, рязане и други действия, нарушаващи целостта им).</li>
  <li>Всички пратки се отварят преди изплащането на сумите обратно. Щом се уверим, че пратката отговаря на посочените условия, сумата ще бъде изплатена обратно към вас в срок от 3 работни дни с пощенски паричен превод. За получаването на паричен превод в офиса на куриера ще Ви изискат единствено лична карта. При неспазване на описаните условия, искането за рекламация няма да бъде разгледано.</li>
</ol>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
