import React, { useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { Typography, Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  orderSuccess: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    padding: "9rem",
    backgroundColor: theme.palette.common.white,
  },
  successIcon: {
    fontSize: "8rem",
    color: theme.palette.success.main,
    marginBottom: theme.spacing(4),
  },
  successText: {
    marginBottom: theme.spacing(2),
    fontWeight: "bold",
    fontSize: "2rem",
    color: theme.palette.text.primary,
  },
  link: {
    textDecoration: "none",
  },
  viewOrdersButton: {
    marginTop: theme.spacing(4),
    padding: theme.spacing(2, 4),
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    borderRadius: theme.spacing(4),
    textTransform: "uppercase",
    letterSpacing: 2,
    fontWeight: "bold",
    transition: "background-color 0.3s ease",
    "&:hover": {
      backgroundColor: "#9caccc",
    },
  },
}));

function OrderSuccess() {
  const { isAuthenticated } = useSelector((state) => state.userData);
  const classes = useStyles();

  return (
    <div className={classes.orderSuccess}>
      <CheckCircleIcon className={classes.successIcon} />

      <Typography variant="h4" className={classes.successText}>
        Поръчката Ви е приета успешно.
        <br />
        Благодарим Ви!
      </Typography>
      {isAuthenticated ?
        <Link to="/orders" className={classes.link}>
          <Button variant="contained" className={classes.viewOrdersButton}>
            Преглед на поръчките
          </Button>
        </Link> :   
        <>
          <Typography
            variant="h4"
            component="h1"
            className="profileHeading"
            style={{ marginTop: "1.5rem" }}
          >
            Следете имейла си за статуса на поръчката Ви до нейното пристигане.
          </Typography>
          <Link to="/shop" className={classes.link}>
          <Button variant="contained" className={classes.viewOrdersButton}>
            Обратно в магазина
          </Button>
        </Link>
        </>            
        
}
    </div>
  );
}

export default OrderSuccess;
